import React from 'react';

import { iconPositionType } from '../IconTextLink';

import cx from '../../utils/classnames';

import styles from './iconText.module.scss';

export type Props = {
  /** Custom style for wrapper */
  className?: string;

  /** Element group id */
  groupId?: string;

  /** Custom icon to show */
  icon?: React.ReactNode;

  /** Spacing between icon and text in pixel */
  spaceBetween?: number;

  /** Position of display icon */
  iconPosition?: iconPositionType;

  /** Custom text to show */
  text?: React.ReactNode;

  /** Custom style for text */
  textClassName?: string;
} & React.HTMLProps<HTMLDivElement>;

export default function IconText(props: Props) {
  const {
    className,
    groupId,
    icon,
    text = '',
    iconPosition = 'left',
    spaceBetween = 0,
    textClassName,
    ...rest
  } = props;

  const customIconAttr = spaceBetween
    ? { style: { [iconPosition === 'left' ? 'marginRight' : 'marginLeft']: spaceBetween } }
    : undefined;

  return (
    <div className={cx(className, styles.iconTextWrapper, styles[iconPosition])} {...rest}>
      {icon && (
        <span
          aria-hidden="true"
          className={styles.icon}
          data-testid="iconText-icon"
          {...customIconAttr}
        >
          {icon}
        </span>
      )}
      <span className={cx(textClassName, styles.text)}>{text}</span>
    </div>
  );
}
